import React, { useState } from "react";
import MiraiPay from "@miraipay/react-sdk";
import { faker } from "@faker-js/faker";
import { Cart, CartItem, Payment, PaymentStatus } from "./types";

import miraiPayLogo from "./logo.png";
import visaMasterLogo from "./masterCard.svg";
import { HttpsCallable } from "@firebase/functions";

import logoTest from "./2560px-Zara_Logo.svg.png";

interface CheckoutProps {
	cartItems: CartItem[];
	getPaymentId: HttpsCallable<unknown, unknown>;
	onSucess: () => void;
	onCancel: () => void;
}
const Checkout: React.FC<CheckoutProps> = ({ cartItems, getPaymentId, onCancel }) => {
	const user = faker.person;
	const address = faker.location;

	const [orderId, setOrderId] = useState<string>("");
	const [paymentId, setPaymentId] = useState<string>("");
	const [totalAmount, setTotalAmount] = useState(0);
	const [showCheckout, setShowCheckout] = useState(false);
	const [showMiraiPay, setShowMiraiPay] = useState(false);
	const [showLoading, setShowLoading] = useState(false);

	const checkout = () => {
		if (orderId !== "" && paymentId !== "" && totalAmount !== 0) {
			setShowMiraiPay(true);
			return;
		}

		setShowLoading(true);
		const cart: Cart = {
			items: cartItems.map((item) => {
				return {
					id: item.id,
					quantity: item.quantity,
				};
			}),
			vouchers: [],
			shippingZipCode: parseInt(address.zipCode()),
			shippingAddress: address.streetAddress(),
			billingAddress: address.streetAddress(),
		};
		getPaymentId(cart).then((result) => {
			setOrderId((result.data as any).orderId);
			setPaymentId((result.data as any).paymentId);
			setTotalAmount((result.data as any).totalAmount);
			setShowMiraiPay(true);
			setShowLoading(false);
		});
	};

	const finishCheckout = (payment: Payment) => {
		setShowMiraiPay(false);
		setShowCheckout(false);

		if (payment.status === PaymentStatus.SUCCESS) {
			alert("Payment success");
		}
	};

	const cancelCheckout = () => {
		setShowMiraiPay(false);
		setShowCheckout(false);

		setOrderId("");
		setPaymentId("");
		setTotalAmount(0);

		onCancel();
	};

	if (showMiraiPay) {
		return (
			<MiraiPay
				backgroundColor={"#f5f5f5"}
				partnerLogo={logoTest}
				textColor={"#000000"}
				paymentId={paymentId}
				totalAmount={parseFloat(totalAmount.toFixed(2))}
				currency="USDT"
				onPaymentExpired={() => setShowMiraiPay(false)}
				onPaymentCancel={() => cancelCheckout()}
				onPaymentConfirmed={console.log}
				onUserComeback={(payment) => finishCheckout(payment)}
				fontFamily="Roboto"
				isMainnet={false}
			/>
		);
	}

	return (
		<>
			<button type="button" className="checkout" onClick={() => (cartItems.length > 0 ? setShowCheckout(true) : setShowCheckout(false))}>
				checkout
			</button>
			<div className={showCheckout === true ? "checkoutPage" : "checkoutPage d-none"}>
				<div className="modal">
					{/* Need add loading */}
					{showLoading && (
						<div className="demoPage">
							<div className="loadingWrapper">
								<div className="loading"></div>
								<div>Loading...</div>
							</div>
						</div>
					)}
					<div className="modal-dialog modal-lg modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header border-0 px-lg-4">
								<h5 className="modal-title" id="exampleModalCenterTitle">
									Checkout Information
								</h5>
								<button type="button" className="close" onClick={() => setShowCheckout(false)}>
									<span aria-hidden="true">×</span>
								</button>
							</div>
							<div className="modal-body pt-2 px-lg-4">
								<div className="row">
									<div className="col-md-6">
										<h6 className="mb-3">Shipping Address</h6>
										<div className="form-floating mb-3">
											<select className="form-select" id="floatingSelect">
												<option value="3">{address.country()}</option>
											</select>
											<label>Country</label>
										</div>
										<div className="row g-2">
											<div className="col-md-6">
												<div className="form-floating mb-3">
													<input type="text" className="form-control" id="floatingInput" placeholder="First name" readOnly value={user.firstName()} />
													<label>First name</label>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-floating mb-3">
													<input type="text" className="form-control" id="floatingInput" placeholder="Last name" readOnly value={user.lastName()} />
													<label>Last name</label>
												</div>
											</div>
										</div>
										<div className="form-floating mb-3">
											<input type="text" className="form-control" id="floatingInput" placeholder="Address" readOnly value={address.streetAddress()} />
											<label>Address</label>
										</div>
										<div className="form-floating mb-3">
											<input type="text" className="form-control" id="floatingInput" placeholder="Apartment, suite, etc. (optional)" readOnly />
											<label>Apartment, suite, etc. (optional)</label>
										</div>
										<div className="row g-2">
											<div className="col-md-6">
												<div className="form-floating mb-3">
													<input type="text" className="form-control" id="floatingInput" placeholder="City" readOnly value={address.city()} />
													<label>City</label>
												</div>
											</div>
											<div className="col-md-6">
												<div className="form-floating mb-3">
													<input type="text" className="form-control" id="floatingInput" placeholder="Postal code" readOnly value={address.zipCode()} />
													<label>Postal code</label>
												</div>
											</div>
										</div>
										<div className="form-floating mb-3">
											<input type="text" className="form-control" id="floatingInput" placeholder="AddrPhoneess" readOnly value={faker.phone.number("####-###-###")} />
											<label>Phone</label>
										</div>
									</div>
									<div className="col-md-6">
										<h6 className="mb-0">Order Items</h6>
										<div className="listOrder">
											{cartItems.map((item) => {
												return (
													<div className="cart-item" key={item.id}>
														<img src={item.images[0]} alt="" width={64} height={80} style={{ objectFit: "cover" }} />
														<div className="info">
															<div className="name">{item.name}</div>
															<div className="sub">Price: ${item.price}</div>
															<div className="quantity">Quantity: {item.quantity}</div>
														</div>
														<div className="right">
															<div className="price">${(item.quantity * item.price).toFixed(2)}</div>
														</div>
													</div>
												);
											})}
										</div>
										<div className="mt-3">
											<div className="d-flex small my-1">
												<div>Subtotal</div>
												<div className="ms-auto">
													$
													{cartItems
														.reduce((accumulator, object) => {
															return accumulator + object.quantity * object.price;
														}, 0)
														.toFixed(2)}
												</div>
											</div>
											<div className="d-flex small my-1">
												<div>Shipping</div>
												<div className="ms-auto">Free</div>
											</div>
											<div className="d-flex my-1 h6">
												<div>Total</div>
												<div className="ms-auto">
													$
													{cartItems
														.reduce((accumulator, object) => {
															return accumulator + object.quantity * object.price;
														}, 0)
														.toFixed(2)}
												</div>
											</div>
										</div>
										<div className="mt-3">
											<button onClick={() => checkout()} type="button" className="btn btn-primary w-100 mb-3 d-flex align-items-center py-2">
												<img src={miraiPayLogo} alt={"Mirai Pay"} height={20} className="me-2" />
												<span className="w-100 text-center text-uppercase">USDT with Mirai Pay</span>
											</button>
											<button onClick={() => alert(":)")} type="button" className="btn btn-dark w-100 mb-3 d-flex align-items-center py-2">
												<img src={visaMasterLogo} alt={"Visa/Master"} height={20} className="me-2" />
												<span className="w-100 text-center text-uppercase">Visa/Master</span>
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-backdrop" onClick={() => setShowCheckout(false)}></div>
			</div>
		</>
	);
};

export default Checkout;
