export interface Item {
    id: string;
    name: string;
    price: number;
    images: string[];
}

export interface CartItem extends Item {
    quantity: number;
}

export interface CheckoutItem extends Item {
    quantity: number;
}

export interface Cart {
    items: { id: string, quantity: number }[];
    vouchers: string[];
    billingAddress: string;
    shippingAddress: string;
    shippingZipCode: number;
}

export interface OnChainTransaction {
    chainId: string;
    txid: string;
    amount: number;
}

export enum PaymentStatus {
    PENDING = 'PENDING',
    EXPIRED = 'EXPIRED',
    PARTIAL = 'PARTIAL',
    PENDING_CONFIRM = 'PENDING_CONFIRM',
    SUCCESS = 'SUCCESS',
    DELETED = 'DELETED',
    FAILED = 'FAILED'
}

export interface Payment {
    paymentId: string;
    orderId: string;
    currency: string;
    depositedAmount: number;
    pendingDepositedAmount: number;
    expireAt: string;
    createdAt: string;
    minAmount: number;
    status: PaymentStatus;
    txs: OnChainTransaction[];
}