import React, { useEffect, useState } from "react";
import "./globals.scss";

import { initializeApp } from "firebase/app";
import { getFunctions, httpsCallable } from "firebase/functions";
import { User, getAuth, signInAnonymously } from "firebase/auth";
import { CartItem, Item } from "./types";
import Loading from "./Loading";
import Checkout from "./Checkout";

import logoTest from "./2560px-Zara_Logo.svg.png";

const firebaseConfig = {
	apiKey: "AIzaSyCZuyn223AwnxJcbMV9NIfSvIIQGAkXQyI",
	authDomain: "miraipay.firebaseapp.com",
	projectId: "miraipay",
	appId: "1:46953300544:web:013480d569e71e913ed5a5",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const functions = getFunctions(app, "asia-east2");
const getItems = httpsCallable(functions, "getItems");
const getPaymentId = httpsCallable(functions, "getPaymentId");

function App() {
	const [user, setUser] = useState<User>();
	const [items, setItems] = useState<Item[]>([]);
	const [cartItems, setCartItems] = useState<CartItem[]>([]);
	const [showCard, setShowCart] = useState(false);

	useEffect(() => {
		getItems()
			.then((result) => {
				setItems((result.data as any).items);
			})
			.catch((error) => {
				console.log(error);
			});

		signInAnonymously(auth).then((userCredential) => {
			setUser(userCredential.user);
		});
	}, []);

	const addToCart = (item: Item) => {
		const cartItem = cartItems.find((i) => i.id === item.id);
		if (cartItem) {
			cartItem.quantity++;
			setCartItems([...cartItems]);
		} else {
			setCartItems([...cartItems, { ...item, quantity: 1 }]);
		}
	};

	const reduceQuantity = (item: CartItem) => {
		if (item.quantity > 1) {
			item.quantity--;
			setCartItems([...cartItems]);
		} else {
			setCartItems(cartItems.filter((i) => i.id !== item.id));
		}
	};

	const increaseQuantity = (item: CartItem) => {
		item.quantity++;
		setCartItems([...cartItems]);
	};

	if (items.length === 0) return <Loading />;

	return (
		<main>
			<div className="topbar">
				<div className="container">
					<h1 className="h4 mb-0 d-flex align-items-center">
						<img src={logoTest} alt="Mirai Pay" height="32" className="me-2" style={{ filter: "invert(1)" }} />
						Demo Store
					</h1>
				</div>
			</div>

			<div className="demoPage">
				<div className="listTitle">{items.length} Product(s) found</div>
				<div className="product-list">
					{items.map((item) => {
						const priceFloor = Math.floor(item.price);
						const priceCeil = (item.price - priceFloor).toFixed(2).slice(2);
						return (
							<div className="item" key={item.id}>
								<div>
									<img src={item.images[0]} alt={item.name} />
								</div>
								<div className="name">{item.name}</div>
								<div className="price">
									<b>{priceFloor}</b>.{priceCeil} USD
								</div>
								<button type="button" className="atc" onClick={() => addToCart(item)}>
									Add to cart
								</button>
							</div>
						);
					})}
				</div>
			</div>

			<div className={showCard === true ? "shoppingCart show" : "shoppingCart"}>
				<button type="button" className="btnCart" onClick={() => setShowCart(false)}>
					<span>X</span>
				</button>
				<div className="header">
					<div className="icon" onClick={() => setShowCart(true)}>
						<span className="ic-cart"></span>
						<span className="countNumb">{cartItems.length}</span>
					</div>
					<span className="text">Cart</span>
				</div>
				<div className="body">
					{cartItems.map((item) => {
						return (
							<div className="cart-item" key={item.id}>
								<img src={item.images[0]} alt="" width={64} height={80} style={{ objectFit: "cover" }} />
								<div className="info">
									<div className="name">{item.name}</div>
									<div className="sub">Price: ${item.price}</div>
									<div className="quantity">Quantity: {item.quantity}</div>
								</div>
								<div className="right">
									<div className="price">$ {(item.quantity * item.price).toFixed(2)}</div>
									<div className="spinbtn">
										<button type="button" onClick={() => reduceQuantity(item)}>
											-
										</button>
										<button type="button" onClick={() => increaseQuantity(item)}>
											+
										</button>
									</div>
								</div>
							</div>
						);
					})}
				</div>
				<div className="footer">
					<div className="total">
						<div>SUBTOTAL</div>
						<b>
							$
							{cartItems
								.reduce((accumulator, object) => {
									return accumulator + object.quantity * object.price;
								}, 0)
								.toFixed(2)}
						</b>
					</div>
					<Checkout onCancel={() => setCartItems([])} onSucess={() => setCartItems([])} cartItems={cartItems} getPaymentId={getPaymentId} />
				</div>
			</div>
		</main>
	);
}

export default App;
