const Loading: React.FC = () => {
	return (
		<main>
			<div className="demoPage">
				<div className="loadingWrapper">
					<div className="loading"></div>
					<div>Loading...</div>
				</div>
			</div>
		</main>
	);
};

export default Loading;
